import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AppUpdateDialogComponent } from './app-update-dialog/app-update-dialog.component';
import { LocationSelectorDialogComponent } from './location-selector/location-selector-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxLoadingControlModule } from '@runette/ngx-leaflet-loading';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FuryAlertBoxModule } from '../alert-box/alert-box.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingOverlayModule } from '../loading-overlay/loading-overlay.module';
import { EmailViewerDialogComponent } from './email-viewer/email-viewer-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { FindCompanyDialogComponent } from './find-company-dialog/find-company-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { ConfirmLosingChangesDialogComponent } from './confirm-losing-changes-dialog/confirm-losing-changes-dialog.component';
import { MaterialModule } from '../material-components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTableModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    LeafletModule,
    NgxLoadingControlModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    FuryAlertBoxModule,
    MatIconModule,
    FlexLayoutModule,
    LoadingOverlayModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    ScrollbarModule,
    MaterialModule,
    InfiniteScrollModule
  ],
  declarations: [
    DeleteDialogComponent,
    AppUpdateDialogComponent,
    LocationSelectorDialogComponent,
    EmailViewerDialogComponent,
    FindCompanyDialogComponent,
    ConfirmLosingChangesDialogComponent,
  ],
  exports: [
    DeleteDialogComponent,
    AppUpdateDialogComponent,
    LocationSelectorDialogComponent,
    EmailViewerDialogComponent,
    FindCompanyDialogComponent,
    ConfirmLosingChangesDialogComponent,
  ]
})

export class DialogsModule {
}
