import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { IndexButton } from '../interfaces/base/IndexButton';
import { ReplaySubject } from 'rxjs';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ScrollPosition } from '../interfaces/base/scroll-position';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public scrollbar: InfiniteScrollDirective;
  public scrollbarRef: ElementRef;

  public toolbarButtons$: ReplaySubject<IndexButton[]> = new ReplaySubject<IndexButton[]>(1);
  public pageTitle$: ReplaySubject<string> = new ReplaySubject<string>(1);

  private _isLoading: boolean = false;

  public onScrollDown: EventEmitter<any> = new EventEmitter<any>();
  public resetInfiniteScroll: EventEmitter<any> = new EventEmitter<any>();

  public infiniteScrollDisabled: boolean = false;

  private scrollPosition: ScrollPosition[] = [];

  constructor() {
    this.resetInfiniteScroll.subscribe((): void => {
      if (typeof this.scrollbar !== 'undefined') {
        this.scrollbar.destroyScroller();
        this.scrollbar.setup();
      }
    });
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    setTimeout((): void => {
      this._isLoading = value;
    }, 0);
  }

  setScrollPosition(page: string, offset: number): void {
    const index: number = this.scrollPosition.findIndex((d: ScrollPosition): boolean => d.page === page);

    if (index !== -1) {
      this.scrollPosition[index] = {
        page: page,
        offset: offset
      };
    } else {
      this.scrollPosition.push({
        page: page,
        offset: offset
      });
    }
  }

  getScrollPosition(page: string): number | null {
    const index: number = this.scrollPosition.findIndex((d: ScrollPosition): boolean => d.page === page);

    return (index !== -1 ? this.scrollPosition[index].offset : null);
  }
}
