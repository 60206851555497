import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@fury/utils/check-router-childs-data';
import { BackdropDirective } from '../../@fury/shared/backdrop/backdrop.directive';
import { LayoutService } from './layout.service';
import { environment } from '../../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { ApiService } from '../services/base/api.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import isFunction from 'lodash-es/isFunction';
import { AuthenticationService } from '../services/base/authentication.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { IndexButton } from '../interfaces/base/IndexButton';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('backdrop') backdrop: BackdropDirective;
  @ViewChild('reportPanelPlaceholder', {read: ViewContainerRef}) reportPanelPlaceholder: ViewContainerRef;

  @ViewChild('scrollBar', {read: InfiniteScrollDirective}) set scrollBar(scrollBar: InfiniteScrollDirective) {
    this.layoutService.scrollbar = scrollBar;
  }

  @ViewChild('scrollBar', {read: ElementRef}) set scrollBarRef(scrollBar: ElementRef) {
    this.layoutService.scrollbarRef = scrollBar;
  }

  scrollDisabled$: Observable<any> = this.router.events.pipe(
    filter<NavigationEnd>((event: NavigationEnd): boolean => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  public badConnection: boolean = false;
  public isStaging: boolean = false;

  public isOpen: boolean;
  public isLoadingImpersonation: boolean = false;
  public isLoadingLogout: boolean = false;

  public leftToolbarButtons: IndexButton[] = [];
  public rightToolbarButtons: IndexButton[] = [];

  constructor(@Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef,
              private router: Router,
              private swUpdate: SwUpdate,
              public api: ApiService,
              public layoutService: LayoutService,
              public authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.cd.detectChanges();

    this.layoutService.pageTitle$.subscribe((): void => this.cd.detectChanges());
    this.layoutService.toolbarButtons$.subscribe((buttons: IndexButton[]): void => {
      this.leftToolbarButtons = [];
      this.rightToolbarButtons = [];
      buttons.forEach((button: IndexButton): void => {
        if (button.position === 'left') {
          this.leftToolbarButtons.push(button);
        } else if (button.position === 'right') {
          this.rightToolbarButtons.push(button);
        }
      });

      this.cd.detectChanges();
    });

    this.swUpdate.unrecoverable.subscribe((): void => {
      this.badConnection = true;
      this.renderer.addClass(this.document.body, 'is-staging');
    });

    if (environment.appVersionAddon === '-staging') {
      this.isStaging = true;
      this.renderer.addClass(this.document.body, 'is-staging');
    }
  }

  handleMenuClick(functionOrRoute: Function | string): void {
    if (isFunction(functionOrRoute)) {
      functionOrRoute();
    } else {
      this.router.navigateByUrl(<string>functionOrRoute).then((): void => {
      });
    }
  }

  captureBug(): void {
    this.isOpen = false;
    window.Marker.capture();
  }

  stopImpersonating(): void {
    if (!this.isLoadingImpersonation) {
      this.isLoadingImpersonation = true;
      this.authenticationService.stopImpersonating().then((response: boolean): void => {
        if (response) {
          this.isLoadingImpersonation = false;
          this.isOpen = false;
        }
      });
    }
  }

  logout(): void {
    if (!this.isLoadingLogout) {
      this.isLoadingLogout = true;
      this.authenticationService.logout();
    }
  }

  ngOnDestroy(): void {
  }
}

