import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthorizationService } from './services/base/authorization.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthorizationService],
    canActivateChild: [AuthorizationService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
          permissions: [
            'can-create-reports',
            'can-update-reports',
            'can-delete-reports',
            'can-print-reports',
            'can-view-reports',
            'can-forward-reports',
            'can-assign-new-reports',
            'can-reject-reports',
            'can-resolve-reports',
            'can-read-reports-from-all-organisations',
          ]
        }
      },
      {
        path: 'handleiding',
        loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
      },
      {
        path: 'routering',
        loadChildren: () => import('./pages/routering/routering.module').then(m => m.RouteringModule),
        data: {
          permissions: [
            'config-can-manage-organisations',
            'config-can-manage-departments',
            'config-can-manage-roles',
            'config-can-manage-users',
            'config-can-manage-users-from-all-organisations',
            'config-can-manage-categories',
            'config-can-manage-municipalities',
          ]
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
//    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
