import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterStringModule } from './table-filter-string/table-filter-string.module';
import { TableFilterSelectModule } from './table-filter-select/table-filter-select.module';
import { TableFilterDateModule } from './table-filter-date/table-filter-date.module';
import { TableFilterDateRangeModule } from './table-filter-date-range/table-filter-date-range.module';
import { TableFilterReportNumberModule } from './table-filter-report-number/table-filter-report-number.module';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    TableFilterStringModule,
    TableFilterSelectModule,
    TableFilterDateModule,
    TableFilterDateRangeModule,
    TableFilterReportNumberModule,
  ],
})

export class TableFiltersModule {
}
