import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()

export class ReportPanelService {

  public nextReportId: BehaviorSubject<number | boolean> = new BehaviorSubject<number>(null);
  private _openSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  open$: Observable<boolean> = this._openSubject.asObservable().pipe(delay(0));

  public isOpen: boolean = false;

  constructor() {
  }

  open(report_id?: number | string): void {
    this.nextReportId.next(report_id ? parseInt(<string>report_id, 10) : null);
    this._openSubject.next(true);
    this.isOpen = true;
  }

  close(): void {
    this._openSubject.next(false);
    this.isOpen = false;
  }
}
