<fury-alert-box *ngIf="isStaging" class="warn-absolute" style="border-radius:0;margin:0;">
  This is staging environment!
</fury-alert-box>
<fury-alert-box *ngIf="!api.isOnline" class="warn-absolute" style="border-radius:0;margin:0;">
  Uw bent momenteel offline, er is een internet verbinding nodig om met de applicatie te werken.
</fury-alert-box>
<fury-alert-box *ngIf="badConnection" class="warn-absolute" style="border-radius:0;margin:0;">
  Om goed te werken met de applicatie is er goede internet verbinding nodig, controleer uw internet verbinding.
</fury-alert-box>

<fury-backdrop #backdrop="furyBackdrop"></fury-backdrop>

<div class="container" fxLayout="column">
  <mat-toolbar>
    <div class="left-buttons" *ngIf="leftToolbarButtons">
      <button mat-icon-button *ngFor="let button of leftToolbarButtons"
              (click)="handleMenuClick(button.functionOrRoute)">
        <mat-icon>{{ button.icon }}</mat-icon>
      </button>
    </div>
    <span>{{ (layoutService.pageTitle$ | async) }}</span>
    <span class="toolbar-spacer"></span>
    <div class="right-buttons" *ngIf="rightToolbarButtons">
      <button mat-icon-button *ngFor="let button of rightToolbarButtons"
              (click)="handleMenuClick(button.functionOrRoute)">
        <mat-icon>{{ button.icon }}</mat-icon>
      </button>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="homeRightTopMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #homeRightTopMenu="matMenu" xPosition="before">
      <ng-container *ngIf="(authenticationService.user$ | async)?.impersonated_by">
        <button mat-menu-item disabled class="special">
          <mat-icon>verified_user</mat-icon>
          <span>
            {{ (authenticationService.user$ | async)?.name }} / {{ (authenticationService.user$ | async)?.organisation?.name }}
            <small>{{ (authenticationService.user$ | async)?.role_display_name }}
              / {{ (authenticationService.user$ | async)?.role }}</small>
          </span>
        </button>
        <button mat-menu-item [disabled]="isLoadingImpersonation" (click)="stopImpersonating()">
          <mat-icon>clear</mat-icon>
          <span>Stop impersonating user</span>
          <mat-spinner *ngIf="isLoadingImpersonation" diameter="24"></mat-spinner>
        </button>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container
          *ngIf="!(authenticationService.user$ | async)?.impersonated_by && (authenticationService.user$ | async).role === 'super-admin'">
        <button mat-menu-item routerLink="/routering/gebruikers">
          <mat-icon>people_alt</mat-icon>
          <span>Gebruikers</span>
        </button>
        <mat-divider></mat-divider>
      </ng-container>
      <button mat-menu-item routerLink="/auth/account">
        <mat-icon>account_circle</mat-icon>
        <span>Profiel</span>
      </button>
      <button mat-menu-item routerLink="/auth/wachtwoord-aanpassen">
        <mat-icon>settings</mat-icon>
        <span>Wachtwoord aanpassen</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/handleiding">
        <mat-icon>help</mat-icon>
        <span>Help & support</span>
      </button>
      <button mat-menu-item (click)="captureBug()">
        <mat-icon>bug_report</mat-icon>
        <span>Een probleem melden</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
        <mat-spinner *ngIf="isLoadingLogout" diameter="24"></mat-spinner>
      </button>
    </mat-menu>
  </mat-toolbar>
  <!-- CONTENT -->
  <div [class.scroll-disabled]="scrollDisabled$ | async"
       infinite-scroll
       #scrollBar
       [scrollWindow]="false"
       [infiniteScrollDistance]="1"
       [infiniteScrollDisabled]="layoutService.infiniteScrollDisabled"
       (scrolled)="layoutService.onScrollDown.next($event)"
       cdkScrollable class="content" fxFlex="auto" fxLayout="column">
    <div class="content-inner" fxFlex="auto">
      <fury-loading-overlay [isLoading]="layoutService.isLoading" fill="true"></fury-loading-overlay>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
