import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ImageHandler, Options, VideoHandler } from 'ngx-quill-upload';
import * as Quill from 'quill';
import { FormBuilder, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/videoHandler', VideoHandler);

@Injectable()

export class QuillService {

  public uploadUrl: string = null;
  public uploadType: string = 'postUpload';

  public uploadError: string = null;

  private form = this.fb.group({});

  public minimal = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'size': ['small', false, 'large']}],

      [{'color': []}, {'background': []}],
      [{'align': []}],

      ['link'],
    ],
  };
  public basic = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],

      [{'size': ['small', false, 'large']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],
      [{'align': []}],

      ['link'],
    ],
  };
  public withImagesAndVideos = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],

      [{'size': ['small', false, 'large']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],
      [{'align': []}],

      ['link', 'image', 'video'],
    ],
    imageHandler: {
      accepts: ['png', 'jpg', 'jpeg'], // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
      upload: (file: File): Promise<string> => {
        this.uploadError = null;
        return new Promise((resolve, reject) => {
          if (this.uploadUrl !== null) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

              this.form.removeControl('file');
              this.form.addControl('file', new FormControl(file));

              return this.api[this.uploadType](this.uploadUrl, this.form.value).then(
                (response: ServerResponse): void => {
                  if (typeof response.data !== 'undefined') {
                    resolve(response.data.url);
                  } else {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    reject('Ongeldige reactie.');
                  }
                })
                .catch((error => {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    console.error('Error:', error);
                    reject('Uploaden mislukt');
                  })
                );
            } else {
              this.uploadError = 'Invalid file type!';
              reject('Niet ondersteund bestandstype');
              // Handle Unsupported type logic
            }
          } else {
            this.uploadError = 'No upload URL provided, upload is not available!';
            reject('No upload URL provided, upload is not available!');
          }
        });
      },
    } as Options,
    videoHandler: {
      accepts: ['mp4', 'webm'],  // Extensions to allow for videos (Optional) | Default - ['mp4', 'webm']
      upload: (file: File): Promise<string> => {
        this.uploadError = null;
        return new Promise((resolve, reject) => {
          if (this.uploadUrl !== null) {
            if (file.type === 'video/mp4' || file.type === 'video/webm') {

              this.form.removeControl('file');
              this.form.addControl('file', new FormControl(file));

              return this.api[this.uploadType](this.uploadUrl, this.form.value).then(
                (response: ServerResponse): void => {
                  if (typeof response.data !== 'undefined') {
                    resolve(response.data.url);
                  } else {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    reject('Ongeldige reactie.');
                  }
                })
                .catch((error => {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    console.error('Error:', error);
                    reject('Uploaden mislukt');
                  })
                );
            } else {
              this.uploadError = 'Invalid file type!';
              reject('Niet ondersteund bestandstype');
              // Handle Unsupported type logic
            }
          } else {
            this.uploadError = 'No upload URL provided, upload is not available!';
            reject('No upload URL provided, upload is not available!');
          }
        });
      },
    } as Options
  };
  public forHelpAndSupport = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],

        [{'size': ['small', false, 'large']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],
        [{'align': []}],

        ['link', 'image', 'video'],
        ['html'],
      ],
      handlers: {
        'html': this.addHtmlText.bind(this),
      }
    },
    imageHandler: {
      accepts: ['png', 'jpg', 'jpeg'], // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
      upload: (file: File): Promise<string> => {
        this.uploadError = null;
        return new Promise((resolve, reject) => {
          if (this.uploadUrl !== null) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

              this.form.removeControl('file');
              this.form.addControl('file', new FormControl(file));

              return this.api[this.uploadType](this.uploadUrl, this.form.value).then(
                (response: ServerResponse): void => {
                  if (typeof response.data !== 'undefined') {
                    resolve(response.data.url);
                  } else {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    reject('Ongeldige reactie.');
                  }
                })
                .catch((error => {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    console.error('Error:', error);
                    reject('Uploaden mislukt');
                  })
                );
            } else {
              this.uploadError = 'Invalid file type!';
              reject('Niet ondersteund bestandstype');
              // Handle Unsupported type logic
            }
          } else {
            this.uploadError = 'No upload URL provided, upload is not available!';
            reject('No upload URL provided, upload is not available!');
          }
        });
      },
    } as Options,
    videoHandler: {
      accepts: ['mp4', 'webm'],  // Extensions to allow for videos (Optional) | Default - ['mp4', 'webm']
      upload: (file: File): Promise<string> => {
        this.uploadError = null;
        return new Promise((resolve, reject) => {
          if (this.uploadUrl !== null) {
            if (file.type === 'video/mp4' || file.type === 'video/webm') {

              this.form.removeControl('file');
              this.form.addControl('file', new FormControl(file));

              return this.api[this.uploadType](this.uploadUrl, this.form.value).then(
                (response: ServerResponse): void => {
                  if (typeof response.data !== 'undefined') {
                    resolve(response.data.url);
                  } else {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    reject('Ongeldige reactie.');
                  }
                })
                .catch((error => {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    console.error('Error:', error);
                    reject('Uploaden mislukt');
                  })
                );
            } else {
              this.uploadError = 'Invalid file type!';
              reject('Niet ondersteund bestandstype');
              // Handle Unsupported type logic
            }
          } else {
            this.uploadError = 'No upload URL provided, upload is not available!';
            reject('No upload URL provided, upload is not available!');
          }
        });
      },
    } as Options,
  };

  public quillEditor: any = null;
  public editorEnabled: boolean = true;

  constructor(private api: ApiService,
              private fb: FormBuilder,
              private domSanitizer: DomSanitizer,
  ) {
  }

  toggle(): void {
    this.editorEnabled = !this.editorEnabled;
  }

  maxLength(editor: any, length: number): void {
    if (editor.editor.getLength() > length) {
      editor.editor.deleteText(length, editor.editor.getLength());
    }
  }

  addHtmlText(value) {
    console.log(this.quillEditor);
    const quill = value.quill,
      div: HTMLDivElement = document.createElement('div'),
      div2: HTMLDivElement = document.createElement('div'),
      text: HTMLTextAreaElement = document.createElement('textarea'),
      cancel: HTMLButtonElement = document.createElement('button'),
      save: HTMLButtonElement = document.createElement('button');
    cancel.innerHTML = 'Cancel';
    cancel.type = 'button';
    cancel.addEventListener('click', (): void => {
      document.getElementsByClassName('ql-container')[0].removeChild(div);
    });

    save.innerHTML = 'Insert';
    save.type = 'button';
    save.addEventListener('click', (): void => {
      console.log(text.value);
//      this.element.setValue(this.domSanitizer.bypassSecurityTrustHtml(text.value));
//      this.quillEditor.root.innerHTML = text.value;
      //this.quillEditor.clipboard.dangerouslyPasteHTML(0, this.domSanitizer.bypassSecurityTrustHtml(text.value));
      div.remove();
    });

    text.rows = 8;

    div2.className = 'btn-container';
    div2.appendChild(cancel);
    div2.appendChild(save);

    div.className = 'ql-custom-tooltip';
    div.appendChild(text);
    div.appendChild(div2);

    document.getElementsByClassName('ql-container')[0].appendChild(div);
  }
}
