import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../interfaces/routering/user';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from './api-endpoints.service';
import { HttpBackend } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { WebSocketPermissions } from '../../interfaces/web-socket-permissions';
import { SnackbarService } from './snackbar.service';
import * as Sentry from '@sentry/browser';
import { ReportService } from '../reports/report.service';

@Injectable()

export class AuthenticationService {
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public user$: Observable<User> = this._user as Observable<User>;

  private _eventReceived: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public eventReceived$: Observable<boolean> = this._eventReceived as Observable<boolean>;

  private channelsWithEvents: any = [];
  private pusherEvents: string[] = [
    /*
        '.report.forwarded',
        '.report.exported',
        '.report.export-failed',
        '.company.exported',
        '.company.export-failed',
        '.reporter.exported',
        '.reporter.export-failed',
        '.companies-per-municipalities.exported',
        '.companies-per-municipalities.export-failed',
        '.complaints-per-company.exported',
        '.complaints-per-company.export-failed',
        '.sources.exported',
        '.sources.export-failed',
        '.search.report.exported',
        '.search.report.export-failed',
        '.monthly-report.exported',
        '.monthly-report.export-failed',
        '.report.received.confirmation.sent',
    */
  ];

  constructor(private api: ApiService,
              private httpBackend: HttpBackend,
              private apiEndpoints: ApiEndpointsService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private snackbarService: SnackbarService,
              private reportService: ReportService,
  ) {
    this._user.next(this.localStorageService.get('user'));

    this.user$.subscribe((user: User): void => {
      if (user) {
        Sentry.setUser({
          id: user.id,
          username: user.name,
          email: user.email,
        });

        let i: number = 0;
        const interval: NodeJS.Timeout = setInterval((): void => {
          i++;
          if (typeof window.Echo !== 'undefined') {
            clearInterval(interval);

            // clear old subscriptions, required when impersonating user
            if (this.channelsWithEvents.length) {
              this.channelsWithEvents.forEach((event: string): void => {
                try {
                  window.Echo.private('user.' + user.id).stopListening(event);
                } catch (e) {
                }
              });
              this.channelsWithEvents = [];
            }

            // create new subscriptions
            this.pusherEvents.forEach((event: string): void => {
              if (!environment.production) {
                console.log('WebSocket startSubscription: ', {channel: 'user.' + user.id, event: event});
              }
              this.channelsWithEvents.push({
                channel: 'user.' + user.id,
                events: this.pusherEvents
              });
              window.Echo.private('user.' + user.id).listen(event, (event2: {
                status: boolean,
                report_number: string,
                forwarded_to: string
              }): void => {
                if (!environment.production) {
                  console.log('WebSocket received event for user.' + user.id + ' channel: ' + event2);
                }
                if (event === '.report.forwarded') {
                  if (event2.status) {
                    this.snackbarService.info('Melding ' + event2.report_number + ' is doorgestuurd naar ' + event2.forwarded_to + '.');
                  } else {
                    this.snackbarService.warning('Doorsturen van melding ' + event2.report_number + ' naar ' + event2.forwarded_to + ' is mislukt. Wij proberen het opnieuw.');
                  }
                } else if (event === '.report.received.confirmation.sent') {
                  this.snackbarService.info('De bevestigingsemail is nogmaals verstuurd.');
                } else if (event.indexOf('-failed') !== -1) {
                  this.snackbarService.warning('Het genereren van de export is mislukt. Probeer het opnieuw.');
                } else {
                  this.snackbarService.info('Export is voltooid en is per e-mail verstuurd.');
                }
              });
            });
          }

          if (i > 100) {
            clearInterval(interval);
          }
        }, 500);
      } else {
        Sentry.setUser(null);
        this.channelsWithEvents.forEach((subscription: any): void => {
          if (!environment.production) {
            console.log('WebSocket stopSubscription: ', subscription);
          }
          subscription.events.forEach((event: string): void => {
            window.Echo.private(subscription.channel).stopListening(event);
          });
        });
      }
    });
  }

  login(formData: any): Promise<boolean> {
    return this.api.post(`auth/login`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  logout(): Subscription {
    return this.api.get(`auth/logout`).subscribe((): void => {
      this.silentLogout();
    });
  }

  silentLogout(): void {
    this.localStorageService.delete('api-token');
    this.localStorageService.delete('user');
    this.localStorageService.delete('organisation');

    this._user.next(null);

    this.router.navigate(['/auth/login']).then((): boolean => true);
  }

  validateCompleteAccountToken(id: number, expires: string, signature: string): Observable<ServerResponse> {
    return this.api.get(`auth/complete-account/${id}?expires=${expires}&signature=${signature}`, null, true);
  }

  completeAccount(id: number, expires: string, signature: string, formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/complete-account/${id}?expires=${expires}&signature=${signature}`, formData);
  }

  forgotPassword(formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/forgot-password`, formData);
  }

  validateResetPasswordToken(token: string, email: string): Observable<ServerResponse> {
    return this.api.get(`auth/reset-password/${token}?email=${email}`, null, true);
  }

  resetPassword(formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/reset-password`, formData);
  }

  changePassword(formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/change-password`, formData);
  }

  getAccount(): Observable<ServerResponse> {
    return this.api.get(`auth/account`, null, true);
  }

  updateAccount(formData: any): Promise<boolean> {
    return this.api.post(`auth/account`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          this.localStorageService.set('user', response.data);
          this._user.next(response.data);

          this.router.navigate(['/']).then((): void => {
          });

          return true;
        } else {
          return false;
        }
      })
      .catch((): boolean => false);
  }

  startImpersonating(formData): Promise<boolean> {
    return this.api.post(`auth/impersonate`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);
            this.reportService.shouldReloadReports = true;

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  stopImpersonating(): Promise<boolean> {
    return this.api.delete(`auth/impersonate`, false).toPromise()
      .then
      ((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            this.router.navigate(['/routering/gebruikers']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  startChannelSubscription(webSocket: WebSocketPermissions): void {
    this.user$.pipe(first()).subscribe((user: User): void => {
      if (user) {
        if (!environment.production) {
          console.log('WebSocket startSubscription config: ', webSocket);
        }
        if (user.role === 'super-admin' || user.permissions.some((permission: string) => webSocket.permissions.includes(permission))) {
          webSocket.events.forEach((event: string): void => {
            window.Echo.private(webSocket.channel).listen(event, (): void => {
              if (!environment.production) {
                console.log('WebSocket received event for "' + webSocket.channel + '" channel: ' + event);
              }
              this._eventReceived.next(true);
            });
          });
        }

        if (typeof user.organisation !== 'undefined') {
          if (user.organisation !== null) {
            webSocket.events.forEach((event: string): void => {
              if (!environment.production) {
                console.log(webSocket);
              }
              window.Echo.private(user.organisation.name).listen(event, (): void => {
                if (!environment.production) {
                  console.log('WebSocket: received event for "' + user.organisation.name + '" channel: ' + event);
                }
                this._eventReceived.next(true);
              });
            });
          }
        }
      }
    });
  }

  stopChannelSubscription(webSocket: WebSocketPermissions): void {
    this.user$.pipe(first()).subscribe((user: User): void => {
      if (user) {
        if (!environment.production) {
          console.log('WebSocket stopSubscription config: ', webSocket);
        }
        if (user.role === 'super-admin' || user.permissions.some((permission: string) => webSocket.permissions.includes(permission))) {
          webSocket.events.forEach((event: string): void => {
            window.Echo.private(webSocket.channel).stopListening(event);
          });
        }

        if (typeof user.organisation !== 'undefined') {
          if (user.organisation !== null) {
            webSocket.events.forEach((event: string): void => {
              window.Echo.private(user.organisation.name).stopListening(event);
            });
          }
        }
      }
    });
  }
}
