import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { AuthenticationService } from './services/base/authentication.service';
import { User } from './interfaces/routering/user';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppUpdateDialogComponent } from '../@fury/shared/dialog/app-update-dialog/app-update-dialog.component';
import { SnackbarService } from './services/base/snackbar.service';
import { interval, Observable } from 'rxjs';
import Echo from 'laravel-echo';
import { environment } from '../environments/environment';
import { LocalStorageService } from './services/base/local-storage.service';
import { version } from '../environments/version';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  private swUpdatePending: boolean = false;

  constructor(private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private splashScreenService: SplashScreenService, // required!! otherwise splashScreen does not go away on app load
              private authenticationService: AuthenticationService,
              private swUpdate: SwUpdate,
              private dialogService: MatDialog,
              private snackbarService: SnackbarService,
              private localStorageService: LocalStorageService,
  ) {
    console.log(version + environment.appVersionAddon);
    this.renderer.addClass(this.document.body, 'fury-default');
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    if (this.swUpdate.isEnabled) {
      const oncePerHour$: Observable<number> = interval(360 * 1000);

      oncePerHour$.subscribe((): void => {
        console.log('onceAppIsStable$');
        this.swUpdate.checkForUpdate()
          .then((): void => console.log('checkForUpdate()'))
          .catch((error): void => {
            console.log('Service worker not supported or disabled!');
            throw new Error(error);
          });
      });

      this.swUpdate.versionUpdates.subscribe((event: VersionEvent): void => {
        if (!this.swUpdatePending) {
          if (event.type === 'VERSION_DETECTED') {
            this.snackbarService.info('Er is een nieuwe versie van de applicatie beschikbaar die op de achtergrond wordt klaargezet.');
          }
          if (event.type === 'VERSION_READY') {
            console.log('ServiceWorker update available: yes');
            this.swUpdatePending = true;
            let dialogRef: MatDialogRef<AppUpdateDialogComponent>;
            dialogRef = this.dialogService.open(AppUpdateDialogComponent, {
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(response => {
              if (response === true) {
                this.swUpdate.activateUpdate().then((): void => {
                  window.location.reload();
                });
              }
            });
          }
        }
      });
    }

    this.authenticationService.user$.subscribe((user: User): void => {
      if (user) {
        window.Echo = new Echo({
          ...environment.pusher,
          ...{
            authEndpoint: environment.api_endpoint + 'broadcasting/auth',
            auth: {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.localStorageService.get('api-token')}`
              }
            }
          }
        });

        if (typeof window.Marker !== 'undefined') {
          window.markerConfig.reporter = {
            email: user.email,
            fullName: user.name,
          };

          window.Marker.setCustomData({
            version: version
          });
        }
      }
    });
  }
}
