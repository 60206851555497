import { Injectable } from '@angular/core';
import { Control, DivIcon, Icon, latLng, LayerGroup, Map, MapOptions, Marker, MarkerOptions, tileLayer, TileLayer } from 'leaflet';
import Layers = Control.Layers;

@Injectable()

export class LeafletMapService {

  public mapLayerStandard: TileLayer = tileLayer('https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
    minZoom: 6,
    maxZoom: 19,
  });

  public mapLayerSatellite: TileLayer = tileLayer('https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_orthoHR/EPSG:3857/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
    minZoom: 6,
    maxZoom: 19,
  });

  public mapOptions: MapOptions = {
    layers: [this.mapLayerStandard],
    zoom: 8,
    center: latLng(51.80, 4.94)
  };

  public loadingOptions: any = {
    position: 'topleft',
  };

  public baseMaps = {
    'Achtergrond kaart': this.mapLayerStandard,
    'Luchtfoto kaart': this.mapLayerSatellite
  };

  public markerLayer: LayerGroup = new LayerGroup();
  public mapContainer: Map = null;
  public singleMarker: Marker = null;

  private defaultZoomed: number = 17;

  constructor() {
  }

  init(mapContainer: Map): void {
    this.mapContainer = mapContainer;
    this.mapContainer.addLayer(this.markerLayer);
    this.mapContainer.addControl(new Layers(this.baseMaps));
  }

  marker(type: 'report' | 'company' | 'single' = 'report', iconColor: string = null): MarkerOptions {
    if (type === 'company') {
      return {
        icon: new DivIcon({
          html: '<div class="leaflet-custom-marker-pin company-marker" style="color:' + iconColor + '"></div>',
          iconSize: [18, 18],
          iconAnchor: [9, 18],
        }),
        draggable: false,
      };
    } else if (type === 'single') {
      return {
        icon: new Icon({
          iconUrl: '/assets/img/markers/blue.png',
          iconSize: [64, 64],
          iconAnchor: [32, 64],
        }),
        draggable: false,
      };
    }

    return {
      icon: new DivIcon({
        html: '<div class="leaflet-custom-marker-pin"' + (iconColor ? 'style="color:' + iconColor + '"' : '') + '></div>',
        iconSize: [24, 36],
        iconAnchor: [12, 36],
      }),
      draggable: false,
    };
  }

  updateMarker(coords: [number, number]): void {
    if (this.singleMarker) {
      this.singleMarker.setLatLng({lat: coords[0], lng: coords[1]});
    } else {
      this.singleMarker = new Marker({lat: coords[0], lng: coords[1]}, this.marker('single'));
      this.markerLayer.addLayer(this.singleMarker);
    }

    this.mapContainer.setView(coords, this.defaultZoomed);
  }

  clear(): void {
    this.markerLayer.clearLayers();
    this.singleMarker = null;
  }
}
